
@font-face {
  font-family: 'verlag';
  src: url('./Verlag-Book.eot');
  src:
    url('./Verlag-Book.eot?#iefix')
    format('embedded-opentype'),
    url('./Verlag-Book.woff2') format('woff2'),
    url('./Verlag-Book.woff') format('woff'),
    url('./Verlag-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'verlag';
  src: url('./Verlag-Bold.eot');
  src:
    url('./Verlag-Bold.eot?#iefix')
    format('embedded-opentype'),
    url('./Verlag-Bold.woff2') format('woff2'),
    url('./Verlag-Bold.woff') format('woff'),
    url('./Verlag-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
